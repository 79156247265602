import { combineReducers } from 'redux';
import { authReducer as auth, resetLogin } from '@mfotek/inhub-ui-auth';

const createReducer = asyncReducers =>
  combineReducers({
    auth,
    ...asyncReducers,
  });

const rootReducer = asyncReducers => (state, action) => {
  switch (action.type) {
    // This resets the store to initial state before running
    // the reset of the reducers.
    case [resetLogin]:
      state = undefined;
      break;
  }

  return createReducer(asyncReducers)(state, action);
};

export default rootReducer;
