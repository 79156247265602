import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './app';
import configureStore from './store';

// Setup `redux`
const initialState = {};
const store = configureStore(initialState);

const rootNode = document.getElementById('root');

const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>,
    rootNode,
  );
};

if (module.hot) {
  module.hot.accept(['./app'], () => {
    ReactDOM.unmountComponentAtNode(rootNode);
    render();
  });
}

render();
