import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    font-size: 14px;
    line-height: 1.15;
    text-rendering: optimizeLegibility;
    // Force IE11 & Edge to use a "normal", non-overlapping, non-auto-hiding scrollbar.
    -ms-overflow-style: scrollbar;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    // Changes the default tap highlight to be completely transparent in iOS.
    -webkit-tap-highlight-color: rgba(0,0,0,0);

    @media (min-width: 700px) {
      font-size: 16px;
    }
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  body {
    background: #FFF;
    font-family: "proxima-nova", sans-serif;
    font-weight: 300;
    margin: 0;
  }

  a,
  a:visited,
  a:active {
    color: #0069FF;
  }

  strong {
    font-weight: 700;
  }
`;

export default GlobalStyle;
