// @flow
import _ from 'lodash';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { analyticsMiddleware } from '@mfotek/inhub-ui-analytics';
import { apiMiddleware } from '@mfotek/inhub-ui-api-utils';
import { errorMiddleware } from '@mfotek/inhub-ui-errors';
import {
  isTokenCurrent,
  receiveLogin as loginAction,
  resetLogin as logoutAction,
} from '@mfotek/inhub-ui-auth';
import { showGlobalError } from 'actions/app';
import createReducer from './reducers';

import type { ErrorMiddlewareOptions } from '@mfotek/inhub-ui-errors';

const getToken = state => {
  const stateToken = _.get(state, 'auth.accessToken');

  if (!isTokenCurrent(stateToken)) {
    return null;
  }

  return stateToken;
};

const configureErrorMiddleware = () => {
  const errorOptions: ErrorMiddlewareOptions = {
    loginAction,
    logoutAction,
  };

  if (process.env.INHUB_RELEASE_ID) {
    errorOptions.release = process.env.INHUB_RELEASE_ID;
  }

  return errorMiddleware(process.env.INHUB_SENTRY_DSN, errorOptions);
};

const configureStore = (initialState: any = {}) => {
  const middleware = [
    thunk,
    apiMiddleware({
      defaultOnError: showGlobalError,
      getToken,
      logoutAction,
    }),
    analyticsMiddleware({ trackingId: process.env.INHUB_GA_ID }),
    configureErrorMiddleware(),
  ];

  const enhancers = [applyMiddleware(...middleware)];

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  const composeEnhancers =
    process.env.NODE_ENV !== 'production' &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          shouldHotReload: false,
        })
      : compose;

  const store = createStore(
    createReducer(),
    initialState,
    composeEnhancers(...enhancers),
  );

  // Async reducer registry
  store.asyncReducers = {};

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      store.replaceReducer(createReducer(store.asyncReducers));
    });
  }

  return store;
};

export default configureStore;
