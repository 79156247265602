import React from 'react';
import styled from 'styled-components';
import { Button } from '@mfotek/inhub-ui-button';
import { ErrorInfo } from '@mfotek/inhub-ui-error-boundary';
import * as Styles from '@mfotek/inhub-ui-styles';

type Props = {
  error: Error;
  errorInfo?: ErrorInfo;
};

const TopLevelError: React.FC<Props> = ({ error, errorInfo }) => {
  const [showStack, setShowStack] = React.useState(false);

  let stack;

  if (
    process.env.NODE_ENV !== 'production' &&
    errorInfo &&
    errorInfo.componentStack
  ) {
    stack = (
      <Stack>
        <Button size="small" onClick={() => setShowStack(!showStack)}>
          {showStack ? 'Hide' : 'Show'} Error Details
        </Button>

        {showStack && (
          <StackDetails>
            <h2>Error: {error.message}</h2>
            <pre>{errorInfo.componentStack}</pre>
          </StackDetails>
        )}
      </Stack>
    );
  }

  return (
    <Root>
      <h1>Uh oh, looks like an error occurred!</h1>

      <p>
        Don’t worry, we’ve been notified of this error and will investigate.
        Please{' '}
        <a
          href="#"
          onClick={(event: React.MouseEvent) => {
            event.preventDefault();
            window.location.reload(true);
          }}
        >
          refresh
        </a>{' '}
        and try again, but don’t hesitate to contact us if it continues to
        happen at <a href="mailto:support@theinhub.com">support@theinhub.com</a>
        .
      </p>

      {stack}
    </Root>
  );
};

// STYLES

const Root = styled.div`
  border: 1px solid ${Styles.BORDER_COLOR};
  border-radius: 4px;
  margin-top: 16px;
  padding: 4rem 6rem;

  h1 {
    margin: 0 0 2rem;
  }

  p {
    line-height: 1.5;
  }
`;

const Stack = styled.div`
  margin-top: 2rem;
`;

const StackDetails = styled.div`
  background: ${Styles.CONTENT_BACKGROUND_COLOR};
  color: ${Styles.MUTED_COLOR};
  font-size: 0.75rem;
  line-height: 1.5;
  margin-top: 1rem;
  padding: 1rem;

  h2 {
    margin: 0;
  }

  pre {
    margin: 0;
  }
`;

// COMPOSE

export default TopLevelError;
