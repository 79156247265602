import React from 'react';
import styled from 'styled-components';
import LogoImage from '@mfotek/inhub-ui-logo';
import * as Styles from '@mfotek/inhub-ui-styles';

const PublicLayout: React.FC = ({ children }) => {
  return (
    <Root>
      <Logo>
        <LogoImage />
      </Logo>
      <Wrapper>{children}</Wrapper>
    </Root>
  );
};

// STYLES

const Root = styled.div`
  background: #fafbfc;
  bottom: 0;
  left: 0;
  position: fixed;
  top: 0;
  right: 0;
`;

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: calc(100% - 55px);
`;

const Logo = styled.div`
  align-items: center;
  background: #fff;
  border-bottom: 1px solid ${Styles.BORDER_COLOR};
  display: flex;
  line-height: 1;
  padding: 12px 0;
  justify-content: center;
`;

export default PublicLayout;
