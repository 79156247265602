import { createAction } from 'redux-actions';

export const hideMessage = createAction('APP_MESSAGE_HIDE');
export const showMessage = createAction('APP_MESSAGE_SHOW');

export const showGlobalError = error => ({
  type: 'APP_MESSAGE_SHOW',
  payload: {
    message: error.message,
    type: 'error',
  },
});
