import React from 'react';
import { Route as RouterRoute } from 'react-router-dom';

export { default as AuthenticatedRoute } from './authenticated';

type Props = {
  component: React.ComponentType<any>;
  exact?: boolean;
  path: string;
};

export const Route = ({
  component: Component,
  exact,
  path,
  ...props
}: Props) => (
  <RouterRoute
    exact={exact}
    path={path}
    render={routeProps => <Component {...routeProps} {...props} />}
  />
);
