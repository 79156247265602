// @flow
import React from 'react';
import { Switch, Route } from 'react-router-dom';
import styled from 'styled-components';
import ErrorBoundary from '@mfotek/inhub-ui-error-boundary';
import GlobalSyles from './styles/global';
import Loading from 'components/loading';
import PublicLayout from 'components/public-layout';
import { AuthenticatedRoute } from 'components/routes';
import TopLevelError from 'components/top-level-error';
import Public from 'modules/account';
import MainApp from 'modules/app';

const renderError = (error, errorInfo) => {
  return (
    <PublicLayout>
      <TopLevelError error={error} errorInfo={errorInfo} />
    </PublicLayout>
  );
};

const App = () => (
  <Root>
    <ErrorBoundary renderError={renderError}>
      <React.Suspense fallback={<Loading />}>
        <Switch>
          <Route path="/login" component={Public} />
          <Route path="/logout" component={Public} />
          <Route path="/forgot-password" component={Public} />
          <Route path="/reset-password" component={Public} />
          <AuthenticatedRoute path="/" component={MainApp} />
        </Switch>
      </React.Suspense>
    </ErrorBoundary>
    <GlobalSyles />
  </Root>
);

// STYLES

const Root = styled.div`
  color: #353d4b;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  h7 {
    font-family: inherit;
  }

  input,
  button,
  select,
  textarea {
    font-family: inherit;
    font-weight: 300;
  }
`;

export default App;
