import { logError } from '@mfotek/inhub-ui-errors';

const ABORT_KEY = 'inhub:load:abort';

const isAborted = () =>
  window.localStorage && window.localStorage.getItem(ABORT_KEY) === '1';

export default async function retry<T>(
  fn: () => Promise<T>,
  retriesLeft: number = 3,
  interval: number = 1000,
  exponential: boolean = false,
): Promise<T> {
  try {
    if (isAborted()) {
      throw new Error();
    }

    return fn();
  } catch (error) {
    if (!isAborted()) {
      if (retriesLeft) {
        await new Promise(r => setTimeout(r, interval));

        return retry(
          fn,
          retriesLeft - 1,
          exponential ? interval * 2 : interval,
          exponential,
        );
      }

      logError(`Max retries reached for function ${fn.name}`);

      window.localStorage.setItem(ABORT_KEY, '1');
      window.location.reload(true);
    } else if (window.localStorage) {
      window.localStorage.removeItem(ABORT_KEY);
    }

    throw new Error('Error loading application.');
  }
}
