import { connect } from 'react-redux';
import { checkToken, AuthenticatedRoute } from '@mfotek/inhub-ui-auth';

// COMPOSE

const mapStateToProps = ({ auth }: any) => ({
  accessToken: auth.accessToken,
  isAuthenticated: auth.isAuthenticated,
});

const mapDispatchToProps = {
  onCheckToken: checkToken,
};

const compose = connect(mapStateToProps, mapDispatchToProps);

export default compose(AuthenticatedRoute);
